import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { GwsFilterModule } from './filters/gws-filter.module';
import { DatePipe } from '@angular/common';
import { DragAndDropDirective } from './pages/ui-only/directives/drag-and-drop/drag-and-drop.directive';
import { IdbService } from './services/indexdb.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderService } from './services/core/loader.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import player from 'lottie-web';

export function playerFactory(): any {  
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent, DragAndDropDirective],
  imports: [
    HttpClientModule,
    NgbModule,    
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    GwsFilterModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    NgSelectModule,        
  ],
  providers: [
    IdbService,
    Keyboard,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService, multi: true }   
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
