import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements HttpInterceptor {

  constructor() { }

  intercept(req:HttpRequest<any>,next:HttpHandler): Observable<HttpEvent<any>>{
    let _loader = Array.from(document.querySelectorAll<HTMLElement>('.fc-dynamic-' + req.body.loader + ', .fc-dynamic-' + req.body.loader + '-bg-load'))
    _loader.forEach((ele) => {
      if (ele.classList.contains('fc-dynamic-' + req.body.loader)) {
        ele.style.display = 'flex';
      }
      if (ele.classList.contains('fc-dynamic-' + req.body.loader + '-bg-load')) {
        ele.style.filter = 'brightness(0.9)';
      }
    });
   
    return next.handle(req).pipe(finalize(() => {
      let _loader = Array.from(document.querySelectorAll<HTMLElement>('.fc-dynamic-' + req.body.loader + ', .fc-dynamic-' + req.body.loader + '-bg-load'))
      _loader.forEach((ele) => {
        if (ele.classList.contains('fc-dynamic-' + req.body.loader)) {
          ele.style.display = 'none';
        }
        if (ele.classList.contains('fc-dynamic-' + req.body.loader + '-bg-load')) {
          ele.style.filter = 'none';
        }
      });
    })
    )
  }
}
