import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { StorageService } from './core/storage.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  renderer :Renderer2;
  constructor(private storage_service:StorageService,private renderfactory:RendererFactory2,@Inject(DOCUMENT) private document : Document) { 
    this.renderer =  this.renderfactory.createRenderer(null,null);
  }

  async setDefaultColorMode(){
    let selTheme = await this.storage_service.getDefaultColorMode();
    if (selTheme == null) {
      return;
    } 
    else{
      var root = <HTMLElement>document.querySelector(':root');
      for (const value in selTheme) {
        if (selTheme.hasOwnProperty(value)) {
          if (value == 'primary') {
            root.style.setProperty('--ion-color-primary', `${selTheme[value]}`);
            root.style.setProperty('--ion-color-primary-rgb', this.hexToRGB(`${selTheme[value]}`));
          }
          if (value == 'secondary') {
            root.style.setProperty('--ion-color-secondary', `${selTheme[value]}`);
            //root.style.setProperty('--ion-color-secondary-rgb', this.hexToRGB(`${selTheme.value[value]}`));
          }
          if (value == 'tertiary') {         
          }
        }
      }
      // this.toggleDarkMode(selTheme);
    }
  }

  toggleDarkMode(_selTheme){
    if(_selTheme.text=='gold'){
      this.enableDark();
    }
    else{
      this.enableLight();
    }
  }

  hexToRGB(hex, alpha?) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return r + ", " + g + ", " + b + ", " + alpha;
    } else {
      return r + ", " + g + ", " + b;
    }
  }

  slideUpdate(slider) {
    document.addEventListener('mousewheel', (ev: any) => {
      slider.update();
    }, { passive: false });
    document.addEventListener('click', (ev: any) => {
      slider.update();
    }, true);
    document.addEventListener("touchmove", (ev: any) => {
      slider.update();
    }, { passive: false });
    setTimeout(()=>{slider.update()},300);
  }

  enableDark(){
    this.renderer.addClass(this.document.body,'dark');
  }

  enableLight(){
    this.renderer.removeClass(this.document.body,'dark');
  }
}
