import { Injectable, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingController, ToastController, ToastOptions } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthResponseData } from 'src/app/models/general/auth-response-vm';
import { LoginVM3 } from 'src/app/models/account/login-vm';
import { map } from 'rxjs/operators';
// import { StorageService } from './storage.service';
import * as CryptoJS from 'crypto-jS';
import { interval, Subscription } from 'rxjs';
import { StorageService } from './storage.service';
import { NavController } from '@ionic/angular';
import { IdbService } from 'src/app/services/indexdb.service';

type rTokenConfig = { rToken: string, rTokenMin: number };
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private fcApiUrl: string;
  private forgotPassword: string;
  private subscriptionRefreshToken: Subscription;
  constructor(
    public toast_ctrl: ToastController,
    private http: HttpClient,
    private toast_controller: ToastController,
    private loading_ctrl: LoadingController,
    private idb_service: IdbService,
    private storageService: StorageService,
    private nav_controller: NavController
  ) {
    this.fcApiUrl = environment.fcApiUrl;
    this.forgotPassword = environment.forgotPassword;
  }

  private httpOptionsAuthKeyBased = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      ClientKey: environment.clientKey,
      Authorization: environment.clientAuthKey
    })
  };

  getFcApiUrl(url: string | null): string {
    if (url == null) {
      return this.fcApiUrl;
    }
    return this.fcApiUrl + url;
  }

  getHttpOptionsByKey() {
    return this.httpOptionsAuthKeyBased;
  }

  getforgotPasswordUrl() {
    return this.forgotPassword;
  }

  async getHttpOptionsByToken() {
    let token = await this.idb_service.getTkn()
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ClientKey: environment.clientKey,
        Authorization: 'Bearer ' + token   //window.localStorage.getItem('ACCESS_TOKEN'),
      }),
    };
  }

  async getHttpOptionsByTokenProgress() {
    let token = await this.idb_service.getTkn();
    return {
      reportProgress: true,
      headers: new HttpHeaders({
        ClientKey: environment.clientKey,
        Authorization: 'Bearer ' + token //window.localStorage.getItem('ACCESS_TOKEN'),
      })
    };
  }

  // getHttpOptionsByTokenForMasterDataApi() {
  //   return {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       // Authorization: 'basic TkIwMDE6NDZlNzY0Zm5oM3M4',
  //       Authorization: `basic ${btoa(environment.masterDataApiKey)}`
  //     })
  //   };
  // }

  // left intentionally
  // private async fundCoreApiSessionOut(error: any) {
  //   //
  //   const loginVM = this.readLoginCred();
  //   if (loginVM == null) {
  //     await this.warnToast('Session Expired. Please login.');
  //     await this.router.navigate(['login']);
  //   } else {
  //     await this.warnToast('Restoring Session...');
  //     // await this.warnToast('Session Expired. Restoring...');
  //     const loginResponse = await this.getLoginResponse(loginVM);
  //     if (loginResponse == null) {
  //       return;
  //     }
  //     this.login(loginResponse);

  //     const authData = JSON.parse(this.storageService.readAuthData()) ;
  //     switch (authData.LoginUserType) {
  //       case 2:
  //         this.router.navigate(['advisor-dashboard']);
  //         break;
  //       default:
  //         this.router.navigate(['investor-dashboard']);
  //         break;
  //   }

  //    // await this.router.navigate(['investor-dashboard']);
  //     // await this.router.navigate(['quick-access']);
  //   }
  // }

  showMessage(message: string) {
    alert(message);
  }

  handlePassThrowError(resp: HttpErrorResponse): any {
    // GJ: code is not complete yet.
    switch (resp.status) {
      case 401: // Unauthorized
        this.errorToast('401', true);
        break;
      default:
        this.errorToast(resp.status.toString(), true);
    }
  }

  // handleError(resp: HttpErrorResponse): any {    
  //   console.log(resp);
  //   switch (resp.status) {
  //     case 401: // Unauthorized
  //       if (resp.error != null) {
  //         if (resp.error.status_from == 'GWS') {
  //           // this.showMessage(resp.error.message);
  //           this.errorToast(resp.error.message, false);
  //           console.log(resp.error.message);
  //         } else {
  //           //todo: remove later
  //           this.errorToast(resp.error.message, false);
  //           console.log(resp.error.message);
  //         }
  //       } else {
  //         //todo: remove later
  //         this.errorToast(
  //           JSON.stringify(resp) + 'handle error not implemented',
  //           false
  //         );
  //       }
  //       break;
  //     case 406: // Unauthorized

  //       if (resp.error != null) {
  //         if (resp.error.status_from == 'GWS') {
  //           // this.showMessage(resp.error.message);
  //           this.errorToast(resp.error.error.Data, false);
  //           console.log(resp.error.error.Data);
  //         } else {
  //           //todo: remove later
  //           this.errorToast(resp.error.error.Data, false);
  //           console.log(resp.error.error.Data);
  //         }
  //       } else {
  //         //todo: remove later
  //         this.errorToast(
  //           JSON.stringify(resp) + 'handle error not implemented',
  //           false
  //         );
  //       }
  //       break;
  //       case 0:
  //         this.errorToast(
  //           'network issue &#9888;',
  //           false
  //         );
  //       break;
  //     default:
  //       //todo: remove later
  //       this.errorToast(
  //         JSON.stringify(resp) + 'handle error not implemented',
  //         false
  //       );
  //   }
  //   //
  //   // switch (error.status) {
  //   //   case 401: // Unauthorized
  //   //   case 403: // Forbidden
  //   //     this.fundCoreApiSessionOut(error);
  //   //     break;
  //   //   case 406: // Not Acceptable
  //   //     this.warnToast(error.error.Data);
  //   //     break;
  //   //   case 405: // Method Not Allowed
  //   //   case 501: // Not Implemented
  //   //     this.errorToast(error.error.Data);
  //   //     break;
  //   //   default:
  //   //     this.errorToast('Some error occurred.');
  //   //     break;
  //   // }

  //   // 1xx: Informational
  //   // 2xx: Success
  //   // 3xx: Redirection
  //   // 4xx: Client Error
  //   // 5xx: Server Error
  // }

  handleError(resp: HttpErrorResponse): any {    
    switch (resp.status) {
      case 401: // Unauthorized
        // if (resp.error != null) {
        // if (resp.error.status_from == 'GWS') {
        //   // this.showMessage(resp.error.message);
        //   this.errorToast(resp.error.message, false);
        // } else {
        //todo: remove later            
        this.errorToast(resp.statusText, true);
        // this.dataRefreshTokenFn();
        // }
        // } else {
        //   //todo: remove later
        //   this.errorToast(
        //     JSON.stringify(resp) + 'handle error not implemented',
        //     false
        //   );
        // }
        break;
      case 406: // Unauthorized        
        // this.errorToast(resp.error.Data, false);
        if (resp.error != null) {          
          // if (resp.error.status_from == 'GWS') {
          //   // this.showMessage(resp.error.message);
          //   this.errorToast(resp.error.message, false);
          // } else {
          //   //todo: remove later
          //   this.errorToast(resp.error.message, false);
          // }  
          if (resp.error.Data != null) {            
            this.errorToast(resp.error.Data, true);
          }
          // } else {
          //   //todo: remove later
          //   this.errorToast(
          //     JSON.stringify(resp) + 'handle error not implemented',
          //     false
          //   );
          else {            
            this.errorToast(resp.error, true);
          }
        }
        break;
      case 0:
        this.errorToast(
          'network issue &#9888;',
          true
        );
        break;
      default:
        //todo: remove later
        this.errorToast(
          JSON.stringify(resp) + 'handle error not implemented',
          true
        );
    }
    //
    // switch (error.status) {
    //   case 401: // Unauthorized
    //   case 403: // Forbidden
    //     this.fundCoreApiSessionOut(error);
    //     break;
    //   case 406: // Not Acceptable
    //     this.warnToast(error.error.Data);
    //     break;
    //   case 405: // Method Not Allowed
    //   case 501: // Not Implemented
    //     this.errorToast(error.error.Data);
    //     break;
    //   default:
    //     this.errorToast('Some error occurred.');
    //     break;
    // }

    // 1xx: Informational
    // 2xx: Success
    // 3xx: Redirection
    // 4xx: Client Error
    // 5xx: Server Error
  }

  // login(loginResponse: AuthResponseData): void {
  //   this.storageService.setLoginData(loginResponse);
  // }

  // async logout(clearLoginDataOnly: boolean) {
  //   if (clearLoginDataOnly === true) {
  //     this.storageService.clearLoginData();
  //   } else {
  //     // todo: poojan we need to add progress bar (into the called function) since we are awaiting for the logout request.
  //     const url = this.getFundCoreUrl('/fcapi/account/logout');
  //     const httpOptions = this.getHttpOptionsByToken();
  //     await this.http.post<any>(url, {}, httpOptions);
  //     this.storageService.clearLoginData();
  //     //this.router.navigate(['login']);
  //     this.router.navigate(['welcome']);
  //   }
  //   this.globalService.loadFreshInvestors = true;
  // }

  // public readLoginCred(): LoginVM {
  //   let obj: LoginVM = null;
  //   const jsonStr = this.storageService.readLoginCred();
  //   if (jsonStr && jsonStr.length > 0) {
  //     const jsonObj = JSON.parse(jsonStr);
  //     if (jsonObj != null) {
  //       obj = LoginVM.resolve(jsonObj.LoginName, jsonObj.Password, jsonObj.LoginUserType);
  //     }
  //   }
  //   return obj;
  // }

  // public async signout() {
  //   this.storageService.clearLoginData();
  //   this.storageService.clearLoginCred();
  //   await this.logoutFromServer();
  //   await this.router.navigate(['login']);
  // }

  // private async logoutFromServer() {
  //   const url = this.getWonAppiUrl('/fcapi/account/logout');
  //   const httpOptions = this.getHttpOptionsByToken();
  //   await this.http.post<any>(url, {}, httpOptions);
  // }

  // public async getLoginResponse(loginVM: LoginVM) {
  //   const url = this.getWonAppiUrl('/fcapi/account/login');
  //   const data = { LoginName: loginVM.LoginName, Password: loginVM.Password, LoginUserType: loginVM.LoginUserType };
  //   const httpOptions = this.getHttpOptionsByKey();

  //   try {
  //     const response = await this.http.post<any>(url, data, httpOptions).toPromise();
  //     if (response && response.Data) {
  //       return response.Data;
  //     }
  //   } catch (error) {
  //     this.storageService.clearLoginCred();
  //     this.handleError(error);
  //   }
  //   return null;
  // }

  async showToast(msg: string, showClose: boolean) {
    const toastOption: ToastOptions = {
      message: msg,
      position: 'top',
      animated: true,
      // color: 'dark',
      // cssClass:'center-toast-message',
      translucent: true,
      //showCloseButton: true,
    };
    if (showClose) {
      toastOption.buttons = [
        {
          side: 'end',
          icon: 'close-outline',
          // text: 'close',
          handler: () => {
          },
        },
      ];
    }
    //else {
      toastOption.duration = 3000;
    //}
    const toast = await this.toast_controller.create(toastOption);
    await toast.present();
  }

  async warnToast(msg: string, showClose: boolean) {
    const toastOption: ToastOptions = {
      message: msg,
      position: 'top',
      animated: true,
      // color: 'dark',
      cssClass: 'gws-toast-warn',
      translucent: true,
      //showCloseButton: true,
    };
    if (showClose) {
      toastOption.buttons = [
        {
          side: 'end',
          icon: 'close-outline',
          // text: 'close',
          handler: () => {
          },
        },
      ];
    }
    //else {
      toastOption.duration = 2000;
    //}
    const toast = await this.toast_controller.create(toastOption);
    await toast.present();
  }

  async errorToast(msg: string, showClose: boolean) {
    const toastOption: ToastOptions = {
      message: msg,
      position: 'top',
      animated: true,
      // color: 'dark',
      cssClass: 'gws-toast-error',
      translucent: true,
      //showCloseButton: true,
    };
    if (showClose) {
      toastOption.buttons = [
        {
          side: 'end',
          icon: 'close-outline',
          // text: 'close',
          handler: () => {
          },
        },
      ];
    }
   // else {
      toastOption.duration = 2000;
    //}
    const toast = await this.toast_controller.create(toastOption);
    await toast.present();
  }

  async successToast(msg: string, showClose: boolean) {
    const toastOption: ToastOptions = {
      message: msg,
      position: 'top',
      animated: true,
      // color: 'dark',
      cssClass: 'gws-toast-success',
      translucent: true,
      //showCloseButton: true,
    };
    if (showClose) {
      toastOption.buttons = [
        {
          side: 'end',
          icon: 'close-outline',
          // text: 'close',
          handler: () => {
          },
        },
      ];
    }
    //else {
      toastOption.duration = 2000;
    //}
    const toast = await this.toast_controller.create(toastOption);
    await toast.present();
  }

  async infoToast(msg: string, showClose: boolean) {
    const toastOption: ToastOptions = {
      message: msg,
      position: 'top',
      animated: true,
      // color: 'dark',
      cssClass: 'gws-toast-info',
      translucent: true,
      //showCloseButton: true,
    };
    if (showClose) {
      toastOption.buttons = [
        {
          side: 'end',
          icon: 'close-outline',
          // text: 'close',
          handler: () => {
          },
        },
      ];
    }
    else {
      toastOption.duration = 2000;
    }
    const toast = await this.toast_controller.create(toastOption);
    await toast.present();
  }

  async comingSoon() {
    this.warnToast('Coming Soon', false);
  }

  // async showToast(
  //   type?: 'error' | 'warn' | 'success' | 'info',
  //   position?: 'top' | 'middle' | 'bottom',
  //   showClose?: boolean,
  //   message?:string
  // ) {
  //   const toastOption: ToastOptions = {
  //     message: message ? message : 'Write any text here to be shown !',
  //     cssClass: null,
  //     duration: 1000,
  //     position: position ? position : 'top',
  //     buttons: [],
  //     // side: 'end',
  //     // icon: 'close-outline',
  //   };

  //   toastOption.cssClass = type?'gws-toast-' + type:'';

  //   if(showClose){
  //     toastOption.buttons = [
  //       {
  //         side: 'end',
  //         icon: 'close-outline',
  //         // text: 'close',
  //         handler: () => {
  //         },
  //       },
  //     ];
  //   }

  //   const toast = await this.toastController.create(toastOption);
  //   await toast.present();
  // }

  async post(url: string, data: any, httpOptions: {}) {
    return this.http.post<any>(url, data, httpOptions).toPromise();
  }

    async get(url: string, httpOptions: {}) {
      return this.http.get<any>(url, httpOptions).toPromise();
    }  

  postPipe(url: string, data: any, httpOptions: {}) {
    return this.http.post<any>(url, data, httpOptions).pipe(map(results => results));
  }

  loading: HTMLIonLoadingElement;
  async block(message?: string) {
    this.loading = await this.loading_ctrl.create({
      spinner: 'lines-small',
      message: message,
      cssClass: 'gws-custom-loading',
      showBackdrop: true,
      animated: true,
      backdropDismiss: false,
      keyboardClose: true,
    });
    this.loading.present();
  }

  async unblock() {
    if (this.loading) {
      this.loading.dismiss();
    }

  }

  returndata(l, p) {
    let result = '';
    let e = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    e = result;
    return { b: (CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify({ l: l, p: p })), CryptoJS.enc.Utf8.parse(e), { keySize: 16, iv: CryptoJS.enc.Utf8.parse(e), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString()), a: e };
  }

  async dataRefreshTokenFn() {
    var dataRefreshToken: rTokenConfig = await this.idb_service.getRTkn();//JSON.parse(window.localStorage.getItem('R_TOKEN_CONFIG'));
    if (dataRefreshToken != null && dataRefreshToken.rTokenMin > 0) {
      const source = interval((dataRefreshToken.rTokenMin - 1) * 60 * 1000);
      await this.refreshAccessToken();
      if (this.subscriptionRefreshToken) {
        this.subscriptionRefreshToken.unsubscribe();
      }
      this.subscriptionRefreshToken = source.subscribe(async (val) => {
        await this.refreshAccessToken();
      });
    }
  }

  async refreshAccessToken() {
    var aToken = await this.idb_service.getTkn()
    var dataRefreshToken: rTokenConfig = await this.idb_service.getRTkn()

    if (aToken && dataRefreshToken && dataRefreshToken.rToken && dataRefreshToken.rTokenMin > 0) {
      const tokenResponse = await this.getRefreshTokenResponse(dataRefreshToken.rToken);
      if (tokenResponse != null) {
        await this.idb_service.refreshLoginTokenData(tokenResponse);
      }
    }
  }

  public async getRefreshTokenResponse(rToken: string) {
    const url = this.getFcApiUrl('/fcapi/account/new-token');
    const data = { rToken: rToken };
    const httpOptions = await this.getHttpOptionsByToken();
    try {
      const response = await this.post(url, data, httpOptions);
      if (response && response.Data) {
        return response.Data;
      }
    } catch (error) {
      this.storageService.clearLoginCred();
      if (error.status == 500) {
        //this.errorToast(error.error.Data, false);
        console.log(error.error.Data);
      } else if (error.status == 401) {
        this.logoutCredData();
        //this.errorToast('Session Expired, Please Login', false);
        this.nav_controller.navigateForward('login-master');
      }
      else {
        //this.handleError(error);
        console.log(error);
      }
    }
    return null;
  }

  logoutRefresh() {
    if (this.subscriptionRefreshToken) {
      this.subscriptionRefreshToken.unsubscribe();
    }
  }

  logoutCredData() {
    this.storageService.clearLoginCred();
    //this.storageService.clear();
    this.logoutRefresh();
  }

  public async getFcAppPageUrl(routeKey: string, routeParam: any): Promise<string> {
    const token = await this.idb_service.getTkn();
    console.log(token);
    const data = {
      ck: environment.clientKey,
      tkn: token,
      rk: routeKey,
      rp: routeParam
    };
    const url = this.fcApiUrl + '/#!/lgnwt/' + btoa(JSON.stringify(data));
    console.log(url);
    return url;
}

}

