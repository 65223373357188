import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';

// value - the numeric value to transform into indian amount format. Null or NAN will be returned as "".
// currencySymbol - this will be a prefix symbol for amount. default will be ₹
// decimals - how many decimals will be returned after transform. default will be 2. And -1 will return the exact decimal back.
@Pipe({ name: 'AmountPipe' })
export class AmountPipePipe implements PipeTransform {
  transform(
    value: number | string,
    symbol?: 'plusminus' | 'currency' | '',
    decimals?: number | null,
    ifNan?: string,
  ): any {
    // if not a number or null or undefined.
    if (value == null || isNaN(Number(value)) || value.toString().trim() == '') {
      if (ifNan != null) {
        return ifNan;
      } else {
        return '';
      }
    }
    var val = parseFloat(value.toString());
    //if number
    // if (currencySymbol == null) {
    //   currencySymbol = '₹';
    // }


    if (decimals == null) {
      decimals = 2;
    }


    var result = val.toString().split('.');
    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;

    var output =
      otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    if (result[1] && result[1].length < decimals) {
      decimals = result[1].length;
    }

    // if(14 <= decimals){
    //   decimals = 14;
    // }

    if (result.length > 1) {
      if (decimals == 0) {
        output;
      } else if (decimals < 0) {
        output += '.' + result[1];
      } else {
        var _temp = parseFloat('0.' + result[1]).toFixed(decimals);
        // If the original number of decimals is 1, add a trailing zero
        if (result[1].length === 1) {
          _temp += '0';
        }
        output += '.' + (_temp.split('.'))[1];
      }
    }

    // var returnSymbol = '';
    // if (symbol == 'plusminus') {
    //   if (Math.sign(val) == 1) {
    //     returnSymbol = '+';
    //   }
    //   // The else condition is already handled.
    //   // else if(Math.sign(val) == -1){
    //   //   returnSymbol = '-';
    //   // }
    // }
    // else if (symbol == 'currency') {
    //   returnSymbol = '₹';
    // }
    if (Number(output.split('.')[1]) == 0) {
      output = output.split('.')[0];
    }

    // return returnSymbol + output;

    if (symbol == 'plusminus') {
      if (Math.sign(val) == -1) {
        return '(' + Math.abs(Number(output)) + ')';
      }
      // else if(Math.sign(val) == 1){
      //   return output;
      // }
    }
    else if (symbol == 'currency') {
      return '₹' + output;
    }
    return output;
  }
}

@Pipe({ name: 'AmountHumanizerPipe' })
export class AmountHumanizerPipePipe implements PipeTransform {
  transform(
    value: number | string,
    symbol?: 'plusminus' | 'currency' | '',
    decimals?: number | null,
    ifNan?: string,
  ): any {
    if (value == null || isNaN(Number(value)) || value.toString().trim() == '') {
      if (ifNan != null) {
        return ifNan;
      } else {
        return '';
      }
    }
    var val = parseFloat(value.toString());
    var output;
    if (isNaN(parseFloat(val.toString())) || val == null || val == undefined) {
      output = '0';
    }
    else {
      if (decimals == null) {
        decimals = 2;
      }
      //max number to be used for decimal 
      // if(14 <= decimals){
      //   decimals = 14;
      // }

      var result = '';
      var sym;
      if (val > 0) {
        if (val >= 10000000) {
          // result = (val / 10000000).toString().split('.')[1];
          result += (val / 10000000);
          sym = ' Cr';
        } else if (val >= 100000) {
          result += (val / 100000);
          sym = ' L';
        } else if (val >= 1000) {
          result += (val / 1000);
          sym = ' K';
        } else if (val >= 0) {
          result += val;
          sym = ' ';
        }
      }
      else {
        if (val <= -10000000) {
          result += (val / 10000000);
          sym = ' Cr';
        } else if (val <= -100000) {
          result += (val / 100000);
          sym = ' Lac';
        } else if (val <= -1000) {
          result += (val / 1000)
          sym = ' K';
        } else if (val <= -0) {
          result += val;
          sym = ' ';
        }
      }

      if (result.split('.')[1] && result.split('.')[1].length < decimals) {
        decimals = result.split('.')[1].length;
      }
      output = Number(result).toFixed(decimals) + sym;
    }
    var returnSymbol = '';
    if (symbol == 'plusminus') {
      if (Math.sign(val) == 1) {
        returnSymbol = '+';
      }
      // The else condition is already handled.
      // else if(Math.sign(val) == -1){
      //   returnSymbol = '-';
      // }
    }
    else if (symbol == 'currency') {
      returnSymbol = '₹';
    }
    if (Number(output.split('.')[1].split(' ')[0]) == 0) {
      output = output.split('.')[0] + ' ' + output.split('.')[1].split(' ')[1];
    }
    return returnSymbol + output;

    // if (symbol == 'plusminus') {
    //   if (Math.sign(val) == -1) {
    //     return '(' + Math.abs(output) + ')';
    //   }
    //   else if(Math.sign(val) == 1){
    //     return Math.abs(output);
    //   }
    // }
    // else if (symbol == 'currency') {
    //   return '₹' + output;
    // }
  }
}


@Pipe({ name: 'NumberHighlighterPipe' })
export class NumberHighlighterPipePipe implements PipeTransform {
  transform(
    value: number | string,
    compareValue?: number | string
  ): any {
    if (value == null || value == undefined || isNaN(Number(value)) || Number(value) == 0) {
      return '';
    }
    if (compareValue == null || compareValue == undefined || isNaN(Number(compareValue)) || Number(compareValue) == 0) {
      compareValue = 0;
    }

    var val = parseFloat(value.toString());
    var output;
    if (isNaN(parseFloat(val.toString())) || val == null || val == undefined) {
      output = '';
      return output;
    }
    else {
      if (val == Number(compareValue)) {
        output = '';
      }
      else if (val > Number(compareValue)) {
        output = 'fc-clr-status--1';
      }
      else {
        output = 'fc-clr-status--2';
      }
    }
    return output;
  }
}

@Pipe({ name: 'FilterPipe' })
export class FilterPipePipe implements PipeTransform {
  transform(items: any[], search: string, properties: string[]): any {
    if (search == '') {
      return items;
    }
    var splited = search.split(" ");
    for (var i = 0; i < splited.length; i++) {
      // splited[i]='('+splited[i]+'.*?)';
      splited[i] = '' + splited[i] + '.*?';
    }

    var dat1 = splited.join("");
    var dat2 = splited.reverse().join("");
    var searchTerm = new RegExp('(' + dat1 + '|' + dat2 + ')', 'gi');
    return _.filter(items, function (item) {
      let filteredData;
      for (let i = 0; i < properties.length; i++) {
        filteredData = Array.from(item[properties[i]].toString().matchAll(searchTerm)).length > 0;
        if (filteredData == false) {

        }
        else {
          return filteredData;
        }
      }
    })
  }
}

@Pipe({ name: "OrderBy" })
export class OrderByPipe implements PipeTransform {
  transform(
    value: object[], 
    orderByCol?: number|string, //index or string
    sortBy?: boolean
    ): any[] {
      if(sortBy){
        return value.sort(function(a, b) { return a[orderByCol] - b[orderByCol]; })
      }
      else{
        return value.sort(function(a, b) { return b[orderByCol] - a[orderByCol]; })
      }
    // return value.sort((a,b) => a > b);
  
  }
}
@Pipe({name:"safe"})
export class SafePipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer){
  }
  transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
} 

@Pipe({name: 'getLogoPath'})
export class GetLogoPathPipe implements PipeTransform {
  transform(schemeName: string): string {    
    const schemeNameLowerCase = schemeName.toLowerCase();
    const logoMappings: { [key: string]: string } = {
      '360': '360 One logo.png',  
      'invesco': 'Invesco.png',     
      'aditya': 'Aditya Birla.png',  
      'axis': 'Axis MF.png',  
      'bajaj': 'Bajaj Finserv Mutual Fund.png',  
      'bandhan': 'BandhanFinancial Holdings Limited.png',  
      'bank': 'BAnk of india.png',  
      'bnp': 'Baroda BNP Paribas Mutual Fund.png',  
      'canara': 'canara robeco.png',  
      'dsp': 'DSp favicon.ico',    
      'edelweiss': 'Edelwiess.png',       
      'franklin': 'Franklin.png',  
      'groww': 'groww.png',      
      'hdfc': 'HDFC MF.png',     
      'helios': 'Helios.png',     
      'hsbc': 'hsbc.png',     
      'icici': 'icici.png',     
      'idbi': 'idbi.png',     
      'iifcl': 'iifcl.png',      
      'il&fs': 'IL&FS.png',          
      'iti': 'ITI.png',     
      'jm': 'jm_financial.png',     
      'kotak': 'Kotak.png',           
      'lic': 'LIC.png',     
      'mahindra': 'Mahindra Manulife.png', 
      'mirae': 'Mirae.png',  
      'motilal': 'motilal-oswal-mutual-fund.png',
      'navi': 'Navi MF.png', 
      'nippon': 'Nippon.png',
      'nj': 'nj-mutual-fund.png',
      'old': 'Old bridge.png',
      'pgim': 'PGIM.jpeg',
      'pinebridge': 'pinebridge.webp',
      'parag': 'PPFAS.png',
      'ppfas': 'PPFAS.png',
      'principal': 'principal.png',
      'quant': 'quant.png',
      'quantum': 'Quantum.png',
      'sahara': 'sahara_logo.png', 
      'samco': 'SAMCO.png',
      'sbi': 'SBI.png',
      'shriram': 'Shriram.png',   
      'srei': 'SREI.png',
      'sundaram': 'Sundaram.png',
      'tata': 'Tata.png',
      'taurus': 'Taurus.png',
      'trust': 'Trust MF.png',
      'union': 'Union.png',
      'uti': 'UTI.png',
      'whiteoak': 'whiteoak-capial--mutual-fund.png',
      'woc': 'whiteoak-capial--mutual-fund.png',
      'zerodha': 'Zerodha.png',
      'idfc': 'idfc.png',
    };

    const matchedKey = Object.keys(logoMappings)
      .sort((a, b) => b.length - a.length)
      .find(key => schemeNameLowerCase.includes(key));

    return logoMappings[matchedKey] || 'Default amc.png';
  }
}

@Pipe({name: 'getBankLogo'})
export class GetBankLogoPipe implements PipeTransform {
  transform(bankName: string): string {
    const BankNameLowerCase = bankName.toLowerCase();
    console.log(BankNameLowerCase);
    const logoMappings: { [key: string]: string } = {
    'axis': 'axis-bank.png',
    'baroda': 'bob-bank.png',
    'union': 'ubi-bank.png',
    'icici': 'ICICI Bank.png',
    'hdfc': 'hdfc-bank.png',
    'kotak': 'Kotak.png',
    'sbi': 'sbi-bank.png',
    'state': 'sbi-bank.png',
    'State': 'sbi-bank.png',
    'canara': 'canara-bank.png',   
    'yes': 'yes-bank.png',   
    'india': 'boi-bank.png',
    'punjab': 'pnb-bank.png',
    'federal': 'federal-bank.png',
    'standard': 'standard-chartered-bank.png',
    'hsbc': 'hsbc-bank.png',
    'citi': 'citi-bank.png',
    'idbi': 'idbi-bank.png',
    'indusind': 'indusInd-bank.png',
  };
  const matchedKey = Object.keys(logoMappings).find(key => BankNameLowerCase.includes(key));
  return logoMappings[matchedKey] || 'default_bank.png'; 
}
}

@Pipe({name: 'leadingZero'})
export class LeadingZeroPipe implements PipeTransform {
  transform(value: number): string {
    if (value === undefined || value === null) {      
      return '';
    }

    if (value === 0) {     
      return '0';
    } else if (value >= 1 && value <= 9) {      
      return '0' + value.toString();
    } else {      
      return value.toString();
    }
  }
}
