// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fcApiUrl:'https://fundcore-testapp.azurewebsites.net',  
  clientKey: 'gws',
  clientAuthKey:'testuser:@123456#',
  forgotPassword: 'https://fundcore-testapp.azurewebsites.net/#!/forgot-Password'

  //For invest4edu
  // fcApiUrl:'https://investments.invest4edu.com',
  // clientKey: 'invest4edu',
  // clientAuthKey:'i4eduapiuser:!@edu$os&hts',
  // forgotPassword: 'https://fundcore-invest4edu.azurewebsites.net/#!/forgot-Password'

  // For Nirmalbang
  // fcApiUrl:'https://mflogin.nirmalbang.com', 
  // clientKey: 'buckfast',
  // clientAuthKey:'nirmalbang001:nb@#007',
  // forgotPassword: 'https://mflogin.nirmalbang.com/#!/forgot-Password'

  //For Kunvarji
  // fcApiUrl:'https://mf.kunvarjiwealth.com',
  // clientKey: 'kunvarji_wealth',
  // clientAuthKey:'kunvarji_9361:6Ig5@ew7RfzV3HkkF7sUA#1h2tC7uk',
  // forgotPassword: 'https://mf.kunvarjiwealth.com/#!/forgot-Password'
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
