import { NgModule } from '@angular/core';
import { AmountHumanizerPipePipe, AmountPipePipe, FilterPipePipe, NumberHighlighterPipePipe, OrderByPipe, SafePipe, GetLogoPathPipe, GetBankLogoPipe, LeadingZeroPipe } from './gws-filter.pipe';

@NgModule({
  imports: [
  ],
  declarations: [AmountPipePipe,AmountHumanizerPipePipe,NumberHighlighterPipePipe,FilterPipePipe,OrderByPipe,SafePipe, GetLogoPathPipe, GetBankLogoPipe, LeadingZeroPipe],
  exports:[AmountPipePipe,AmountHumanizerPipePipe,NumberHighlighterPipePipe,FilterPipePipe,OrderByPipe,SafePipe, GetLogoPathPipe, GetBankLogoPipe, LeadingZeroPipe]

})
export class GwsFilterModule {}
