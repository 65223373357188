import { Injectable } from '@angular/core';
import { AuthResponseData } from 'src/app/models/general/auth-response-vm';
import { Storage } from '@capacitor/storage';
import { LoginVM } from 'src/app/models/account/login-vm';
import { ThemeVM } from 'src/app/models/theme/theme-mode-vm';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  // setLoginData(loginResponse: AuthResponseData) {
  //   window.localStorage.setItem('ACCESS_TOKEN', loginResponse.access_token);
  //   window.localStorage.setItem('AUTH_DATA', JSON.stringify(loginResponse));
  //   window.localStorage.setItem('PAN', JSON.stringify(loginResponse.pan));
  // }

  clearLoginData() {
    window.localStorage.removeItem('ACCESS_TOKEN');
    window.localStorage.removeItem('AUTH_DATA');
    window.localStorage.removeItem('PAN');
  }

  getCurrentInvestorId() {
    return JSON.parse(window.localStorage.getItem('CURRENT_INVESTOR'));
  }

  setCurrentInvestor(inv: any): void {
    window.localStorage.setItem('CURRENT_INVESTOR', JSON.stringify(inv));
  }

  saveOtpKey(otpKey: string) {
    localStorage.setItem('OTP_KEY', otpKey);
  }

  getOtpKey(): string {
    return window.localStorage.getItem('OTP_KEY');
  }

  getTkn(): string{
    return window.localStorage.getItem('ACCESS_TOKEN');
  }

  getPan():string{
    return window.localStorage.getItem('PAN');
  }

  async setString(key: string, value: string) {
    await Storage.set({ key, value });
  }

  async getString(key: string): Promise<{ value: any }> {
    return (await Storage.get({ key }));
  }

  async setObject(key: string, value: any) {
    await Storage.set({ key, value: JSON.stringify(value) });
  }

  async getObject(key: string): Promise<{ value: any }> {
    const ret = await Storage.get({ key });
    return JSON.parse(ret.value);
  }

  async removeItem(key: string) {
    await Storage.remove({ key });
  }

  async clear() {
    await Storage.clear();
  }

  async getLoginCred(): Promise<any> {
    let _data = await this.getObject('LOGIN_FILTER').then((_response: any) => {
      if (_response != null) {
        return _response;
      }
    });
    return _data;
  }

  setLoginCred(cred: LoginVM.loginCred, bool: boolean) {
    this.setObject('LOGIN_FILTER', {
      name: cred.l,
      pswd: cred.p,
      checked: bool,
    });
  }

  clearLoginCred() {    
    this.removeItem('LOGIN_FILTER');
    this.clear();
  }

  setDefaultColorMode(_item: ThemeVM.selectedThemeModeVM) {
    this.setObject('SELECTED_COLOR_MODE', {
      text: _item.text,
      primary: _item.value.fcPrimary1,
      secondary: _item.value.fcPrimary2,
      tertiary: _item.value.fcPrimary3,
    });
  }

  async getDefaultColorMode(): Promise<any> {
    let _data = await this.getObject('SELECTED_COLOR_MODE').then((_response: any) => {
      if (_response != null) {
        return _response;
      }
    });
    return _data;
  }

  clearDefaultColorMode() {
    this.removeItem('SELECTED_COLOR_MODE');
  }
  
}
