import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IonRouterOutlet, NavController, Platform, PopoverController, ModalController, ActionSheetController } from '@ionic/angular';
import { ApiService } from './services/core/api.service';
import { ThemeService } from './services/theme.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private navigationSubscription: Subscription;
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  constructor(private platform: Platform, private keyboard: Keyboard, private api_service: ApiService, private nav_controller: NavController, private theme_service: ThemeService, private router: Router, private popoverController: PopoverController,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController) {
    this.initializeApp();
    this.backButtonEvent();
  }

  ngOnInit() {
    // Handle router navigation events
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.closeAllOverlays();
      }
    });

    // Handle back button behavior
    this.platform.backButton.subscribeWithPriority(10, async () => {
      await this.closeAllOverlays();
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
   
  private async closeAllOverlays() {
    const popovers = await this.popoverController.getTop();
    if (popovers) await popovers.dismiss();
    
    const modals = await this.modalController.getTop();
    if (modals) await modals.dismiss();
    
    const actionSheets = await this.actionSheetController.getTop();
    if (actionSheets) await actionSheets.dismiss();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      setTimeout(async () => {
        await this.api_service.dataRefreshTokenFn();
      }, 3000);
      this.theme_service.setDefaultColorMode();
      this.keyboard.hideFormAccessoryBar(true);
      this.keyboard.disableScroll(true);
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10000, () => {
      if (this.routerOutlet.canGoBack()) {
        this.nav_controller.back();
      }
      else {
        navigator['app'].exitApp();
      }
    });
  }
}
