import { Injectable } from '@angular/core';
import { AuthResponseData, InvestorExtndListData, RefreshAuthResponseData, InvestorStore } from '../models/general/auth-response-vm';
import { IndexedDBAngular } from 'indexeddb-angular';
import { ToastController, ToastOptions } from '@ionic/angular';
import { predefinedDate, refreshData } from '../models/account/login-vm';
import { InvestorModel } from '../models/investor/investor-vm';
import { MfReportModel } from '../models/report/MfReportModel';

@Injectable({
  providedIn: 'root'
})
export class IdbService {
 
  private idb_service = null;
  constructor(private toast_controller: ToastController,) {
    //this.deleteDatabase()
    this.idb_service =new IndexedDBAngular('fc-db', 1);
    this.idb_service.createStore(1, this.createCollections);
    // this.idb_service.op(1, this.createCollections);
   }

  createCollections(db) {
    db.currentTarget.result.createObjectStore('CURRENT_INVESTOR_STORE');
    db.currentTarget.result.createObjectStore('ACCESS_TOKEN_STORE');
    db.currentTarget.result.createObjectStore('USER_TYPE_STORE');
    db.currentTarget.result.createObjectStore('AUTH_DATA_STORE');
    db.currentTarget.result.createObjectStore('REFRESH_TOKEN_STORE');
    db.currentTarget.result.createObjectStore('OTP_KEY_STORE');
    db.currentTarget.result.createObjectStore('INVESTOR_LIST_STORE');
    db.currentTarget.result.createObjectStore('FORCE_REFRESH_PAGE_STORE');
    db.currentTarget.result.createObjectStore('PREDEFINED_DATE_STORE');
    db.currentTarget.result.createObjectStore('INVESTOR_FUND_DETAIL_DATA_STORE');
    db.currentTarget.result.createObjectStore('INVESTOR_STORE');
    db.currentTarget.result.createObjectStore('SUCCESS_MESSAGE_STORE');
    db.currentTarget.result.createObjectStore('ERRROR_MESSAGE_STORE');
    db.currentTarget.result.createObjectStore('SCHEME_LIST_STORE');
    db.currentTarget.result.createObjectStore('HAS_SINGLE_INVESTOR_STORE');
    db.currentTarget.result.createObjectStore('CURRENT_PAGE_STORE');
  }

  clearLoginData() {
   this.deleteDatabase();
  }

  async openDatabase(){
    await window.indexedDB.open("fc-db",1) ;
   
  }
  deleteDatabase(){
    window.indexedDB.deleteDatabase('fc-db');
  }

  async getByKey(store_name: string, key: string) {
    await this.openDatabase();
    return await this.idb_service.getByKey(store_name, key);
  }

  async addValueToStore(store_name: string, value: any, key:string){
    await this.idb_service.delete(store_name,key) ;
    await this.idb_service.add(store_name,value,key); 
  }

  async setLoginTokenData(loginResponse: AuthResponseData) {
    await this.addValueToStore('ACCESS_TOKEN_STORE', loginResponse.aToken,"ACCESS_TOKEN");
    await this.addValueToStore('REFRESH_TOKEN_STORE', loginResponse.rTokenConfig, "REFRESH_TOKEN");
  }

  async refreshLoginTokenData(loginResponse: RefreshAuthResponseData) {
    await this.addValueToStore('ACCESS_TOKEN_STORE', loginResponse.AccessToken,"ACCESS_TOKEN");
    await this.addValueToStore('REFRESH_TOKEN_STORE', loginResponse.RefreshTokenConfig, "REFRESH_TOKEN");
  }

  async setCurrentInvestor(_current_investor:InvestorExtndListData) {
    await this.addValueToStore('CURRENT_INVESTOR_STORE', _current_investor, "CURRENT_INVESTOR");
  }  

  async setInvestor(_current_investor:InvestorExtndListData) {
    await this.addValueToStore('INVESTOR_STORE', _current_investor, "INVESTOR");
  }  

  async getCurrentInvestorId(){
    let curr_investor = await  this.getByKey('CURRENT_INVESTOR_STORE', "CURRENT_INVESTOR");
    return curr_investor.id;
  }

  async getCurrentInvestor():Promise<InvestorExtndListData>{
    let curr_investor = await  this.getByKey('CURRENT_INVESTOR_STORE', "CURRENT_INVESTOR");
    return curr_investor;    
  }

  async getInvestor():Promise<InvestorExtndListData>{
    let curr_investor = await  this.getByKey('INVESTOR_STORE', "INVESTOR");
    return curr_investor;    
  }

  async saveOtpKey(otpKey: string) {
    await this.addValueToStore('OTP_KEY_STORE', otpKey, "OTP_KEY");
  }

  async getOtpKey() {
    let otp_key = await  this.getByKey('OTP_KEY_STORE', "OTP_KEY");
    return otp_key;
  }

  async setHasSingleInvestor(single_investor: boolean) {
    await this.addValueToStore('HAS_SINGLE_INVESTOR_STORE', single_investor, "SINGLE_INVESTOR");
  }

  async getHasSingleInvestor() {
    let single_investor = await this.getByKey('HAS_SINGLE_INVESTOR_STORE', "SINGLE_INVESTOR");
    return single_investor;
  }

  async setCurrentPage(currentPage: string) {
    await this.addValueToStore('CURRENT_PAGE_STORE', currentPage, "CURRENT_PAGE");
  }
  
  async getCurrentPage() {
    let current_page = await this.getByKey('CURRENT_PAGE_STORE', "CURRENT_PAGE");
    return current_page;
  }

  async setScheme(scheme) {
    await this.addValueToStore('SCHEME_LIST_STORE', scheme, "SCHEME_STORE")
  }

  async getScheme() {
    let _scheme_list = await this.getByKey('SCHEME_LIST_STORE', "SCHEME_STORE");
    return _scheme_list;
  }

  async getTkn(){
    let _token = await  this.getByKey('ACCESS_TOKEN_STORE', "ACCESS_TOKEN");
    return _token;
  }

  async getRTkn(){
    let _r_token = await  this.getByKey('REFRESH_TOKEN_STORE', "REFRESH_TOKEN");
    return _r_token;
  }

  async setInvestorList(_investor_list) {
    await this.addValueToStore('INVESTOR_LIST_STORE', _investor_list,"INVESTOR_LIST");
  }

  async getInvestorList(){
    let _investor_list = await this.getByKey('INVESTOR_LIST_STORE', "INVESTOR_LIST");
    return _investor_list;
  }

  async setInvestorFilterApplied(_refresh_page_list:refreshData) {
    await this.addValueToStore('FORCE_REFRESH_PAGE_STORE', _refresh_page_list,"FORCE_REFRESH_PAGE");
  }

  async getInvestorFilterApplied():Promise<refreshData>{
    let _refresh_page_list:refreshData = await this.getByKey('FORCE_REFRESH_PAGE_STORE', "FORCE_REFRESH_PAGE");
    return _refresh_page_list;
  }

  async setPredefinedDateApplied(_predefined_date_page_list:predefinedDate) {
    await this.addValueToStore('PREDEFINED_DATE_STORE', _predefined_date_page_list,"PREDEFINED_DATE");
  }

  async getPredefinedDateApplied():Promise<predefinedDate>{
    let _refresh_page_list:predefinedDate = await this.getByKey('PREDEFINED_DATE_STORE', "PREDEFINED_DATE");
    return _refresh_page_list;
  }
  
  async setInvestorFundDetail(_selected_fund_detail:any) {
    await this.addValueToStore('INVESTOR_FUND_DETAIL_DATA_STORE', _selected_fund_detail,"INVESTOR_FUND_DETAIL_DATA");
  }

  async getInvestorFundDetail():Promise<any>{
    let _refresh_page_list:predefinedDate = await this.getByKey('INVESTOR_FUND_DETAIL_DATA_STORE', "INVESTOR_FUND_DETAIL_DATA");
    return _refresh_page_list;
  }

  async setInvestorFolioList(_investor_folio) {
    await this.addValueToStore('AUTH_DATA_STORE', _investor_folio, "FOLIO_LIST");
  }

  async getInvestorFolioList(){
    let _investor_folio = await  this.getByKey('AUTH_DATA_STORE', "FOLIO_LIST");
    return _investor_folio;   
  }

  async setCurrentUserType(_user_type:InvestorStore) {
    await this.addValueToStore('USER_TYPE_STORE', _user_type, "USER_TYPE");
  }

  async getCurrentUserType():Promise<InvestorStore> {
    let _current_user_type = await this.getByKey('USER_TYPE_STORE', "USER_TYPE");
    return _current_user_type;
  }

  async setSuccessMessage(_success_message) {
    await this.addValueToStore('SUCCESS_MESSAGE_STORE', _success_message, "SUCCESS_MESSAGE");
  }

  async getSuccessMessage(){
    let _success_message = await  this.getByKey('SUCCESS_MESSAGE_STORE', "SUCCESS_MESSAGE");
    return _success_message;   
  }

  async setErrorMessage(_error_message) {
    await this.addValueToStore('ERRROR_MESSAGE_STORE', _error_message, "ERROR_MESSAGE");
  }

  async getErrorMessage(){
    let _error_message = await  this.getByKey('ERRROR_MESSAGE_STORE', "ERROR_MESSAGE");
    return _error_message;   
  }
 
}
